import React from 'react';


export function Root() {


    return (<div className="h-100 flex flex-col">
        <nav className="itim-regular flex items-center bg-orange-400 text-white">
            <h1 className="ml-5 text-3xl">wordgrow</h1>
            <ul className="flex m-4 ml-auto justify-end text-xl">
                <li className="mr-1 ml-1">home</li>
                <li className="mr-1 ml-1">about</li>
            </ul>
        </nav>
        <main className="flex-1">
            <div className="grid grid-cols-12 h-100">
                <div className="col-span-2"></div>
                <div className="col-span-8">
                    <div className="flex h-100 text-2xl text-gray-700 flex-row justify-center items-center">საიტი მალე
                        ჩაირთვება
                    </div>
                    {/* <Dashboard/>*/}
                </div>
                <div className="col-span-2"></div>
            </div>
        </main>
    </div>)
}
